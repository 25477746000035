// import React from 'react';
// import styled from 'styled-components';

// const Button = styled.button`
//   background-color: transparent;
//   color: #fff;
//   border-radius: 6px;
//   border: 1px solid #fff;
//   padding: 4%;
//   width:9em;
//   cursor:pointer
// `;

// const StartButton = ({ isLoading, onClick, isMeasuring,fromNext }) => {
//   return (
//   );
// };

// export default StartButton;
