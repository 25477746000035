import React from "react";
import styled from "styled-components";
import info from "../assets/icons/info.svg";
import thumsUp from "../assets/icons/thumsUp.svg";
import error from "../assets/icons/error.svg";

const ImageInfo = ({ infoMessage, errorMessage, isMeasuring, onClick }) => {
  return (
    <Info>
      <>
        <img src={infoMessage && isMeasuring ? info : isMeasuring ? thumsUp : ""} />
        <Text>{infoMessage && isMeasuring ? infoMessage : isMeasuring ? "Perfect" : ""}</Text>
        {infoMessage && isMeasuring && <SpanText>Please re position</SpanText>}
      </>
      <ErrorInfo>
        {errorMessage &&
          <>
            <img width="50" height="50" src={error} />
            <SpanText>Sorry Capture Failed!</SpanText>
          </>}
        {!isMeasuring && <Button
          onClick={onClick}
        >{errorMessage? "Try Again":"Start"}</Button>
        }
      </ErrorInfo>
    </Info>
  );
};

const Info = styled.div`
  position: absolute;
  width: auto;
  z-index: 2;
  left: 50%;
  top: 25%;
  transform: translate(-50%, 0%);
`;
const Text = styled.p`
  display: inline;
  color: white;
  margin-left: 0.2rem;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;

`;

const SpanText = styled.p`
  color: white;
  margin-left: 0.2rem;
`;

const ErrorInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Button = styled.button`
  background-color: transparent;
  color: #fff;
  border-radius: 6px;
  border: 2px solid #fff;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  padding: 4%;
  width:9em;
  cursor:pointer;
`;

export default ImageInfo;
