import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import styled from "styled-components";
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

function getErrorMessage(error) {
  let message = "Unknown Error";
  if (error.includes("3003") || error.includes("3500")) {
    message = "Face was not detected for more than 2 instances";
  }
  else if (error.includes("3004")) {
    message = "Frame losses detected during measurement"
  }
  else if (error.includes("3006")) {
    message = "License activation failed"
  }
  else if (error.includes("3008")) {
    message = "Average frame rate is lower than expected"
  }
  else if (error.includes("3009")) {
    message = "Consecutive frames received in incorrect order"
  }
  else if (error.includes("3504")) {
    message = "device orientation does not match session orientation"
  }
  return message;
}

export default function ProgressBar({ processingTimeDuration, isMeasuring, infoMessage, errorMessage }) {
  console.log("ErrorMessage: " + JSON.stringify(errorMessage));
  const useStyles = makeStyles({
    root: {
      position: 'absolute',
      bottom: '0',
      width: '100%',
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: infoMessage ? "#EC2F3A" : "#00BBFF",
      },
    },
    colorPrimary: {
      background: 'transparent',
      height: "3vh"
    },
    staticText: {
      color: infoMessage ? "#A10811" : "#2B4D91",
      fontFamily: "inter",
      fontWeight: "600",
      lineHeight: "20px",
      textAlign: "center",
      fontSize: ".75rem",
      backgroundColor: infoMessage ? "#FDEAEB" : "#EBFAFF"
    }
  });

  const classes = useStyles();

  function LinearProgressWithLabel(props) {
    return (
      <Box display="flex" alignItems="center">
        {/* <Box >
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box> */}

        <Box width="100%">
          <LinearProgress variant="determinate" {...props} className={classes.colorPrimary} />
        </Box>
      </Box>
    );
  }
  const [timeLeft, setTimeLeft] = useState(processingTimeDuration);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    let interval;

    if (isRunning && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isRunning, timeLeft]);

  useEffect(() => {
    if (isMeasuring) {
      setTimeLeft(processingTimeDuration);
      startTimer();
    }
  }, [isMeasuring])
  const startTimer = () => {
    setIsRunning(true);
  };

  return (
    <div className={classes.root}>
      {!errorMessage && <LinearProgressWithLabel value={(processingTimeDuration - timeLeft) * (100 / processingTimeDuration)} />}
      {errorMessage ?
        <ErrorWrapper>
        <WarningRoundedIcon style={{color:"#fff", width:"1em",height:"1em"}}/>
          <Typography style={{color:"#ffffff"}}>{getErrorMessage(errorMessage)}</Typography>
        </ErrorWrapper>
        : <Typography variant="body2" className={classes.staticText}>Collecting  Measurements...</Typography>}
    </div>
  );
}
const ErrorWrapper = styled.div`
    background:#EC2F3A;
    display:flex;
    justify-content:center;
    align-items:center;
    color:#fffff;

      `;
