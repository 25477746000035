import React from "react";
import styled from "styled-components";
import StatsBox from "./StatsBox";
import { Flex } from "./shared/Flex";
import { isMobile } from "@binah/web-sdk";
import { Grid, Paper, makeStyles } from "@material-ui/core";
import infoProgress from '../assets/icons/infoProgress.svg';
import failProgress from '../assets/icons/failProgress.svg';
import progress from '../assets/icons/Progress.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    bottom: "7%",
    padding:"2%",
  },
}));

const vitalSignsDefault = {
  heartRate: {
    label: "Heart Rate",
    value: 90,
    isEnabled: true,
    unit: "BPM"
  },
  breathingRate: {
    label: "Breathing Rate",
    value: 90,
    isEnabled: true,
    unit: "RPM"
  },
  stress: {
    label: "Stress Level",
    value: 90,
    isEnabled: true,
    unit: "Medium"
  },
  hrvSdnn: {
    label: "HRV-SDNN",
    value: 90,
    isEnabled: true,
    unit: "SDNN"
  },
  spo2: {
    label: "Oxygen Saturation",
    value: 90,
    isEnabled: true,
    unit: "SpO2%"
  },
  bloodPressure: {
    label: "Blaood Pressure",
    value: 90,
    isEnabled: true,
    unit: "mmHg"
  }
};

const Stats = ({ vitalSigns, isMeasuring, errorMessage, infoMessage }) => {
  const classes = useStyles();
  let progressIcon = progress;
  let uiBackgroundColor = "normal"
  if (infoMessage) {
    progressIcon = infoProgress;
    uiBackgroundColor = "info";
  }
  else if (errorMessage) {
    progressIcon = failProgress;
    uiBackgroundColor = "error";
  }

  return (
    <>

    <Grid container className={classes.root}>
      {vitalSigns &&
        Object.keys(vitalSigns).map(vitalSign => {
          const label = vitalSigns[vitalSign]?.label;
          const icon = vitalSign;
          const value =
            label === "Blood Pressure" && vitalSigns[vitalSign]?.value?.systolic && vitalSigns[vitalSign]?.value?.diastolic
              ? `${vitalSigns[vitalSign]?.value?.systolic} / ${vitalSigns[vitalSign]?.value?.diastolic}`
              : vitalSigns[vitalSign]?.value;
          const unit = vitalSigns[vitalSign]?.unit;
          return vitalSigns[vitalSign].isEnabled && (
            <StatsBox
              key={vitalSign}
              icon={icon}
              label={label}
              uiBackgroundColor={uiBackgroundColor}
              progressIcon={progressIcon}
              value={value}
              unit={unit}
            />
          );
        })}
    </Grid>
    
    </>
  );
};

export default Stats;
