import React, { useState, useCallback, useRef, useEffect } from "react";
import styled from "styled-components";
import StartButton from "./StartButton";
import useMonitor from "../hooks/useMonitor";
import { mirror } from "./Mirror";
import Stats from "./Stats";
import useError from "../hooks/useError";
import usePrevious from "../hooks/usePrevious";
import useWarning from "../hooks/useWarning";
import ProgressBar from "./progress";
import { Alert, Skeleton } from "@material-ui/lab";
import mask from "../assets/icons/mask.svg";
import faceMask from "../assets/icons/face.svg";
import infoMask from "../assets/icons/infoMask.svg";
import ImageInfo from "./ImageInfo";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const DEFAULT_MEASUREMENT_DURATION = 70;

export const BinahMonitor = ({
  showMonitor,
  cameraId,
  licenseKey = "47C04D-2D6786-450A81-9FFB7F-56D71D-E1C8E8",
  processingTime,
}) => {
  if (!showMonitor) {
    return null;
  }
  const [startMeasuring, setStartMeasuring] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMeasurementEnabled, setIsMeasurementEnabled] = useState(false);
  const [loadingTimeoutPromise, setLoadingTimeoutPromise] = useState();

  const video = useRef(null);
  const processingTimeDuration = processingTime || DEFAULT_MEASUREMENT_DURATION;
  const {
    sessionState,
    vitalSigns,
    offlineMeasurements,
    error,
    warning,
    info,
  } = useMonitor(
    video,
    cameraId,
    processingTimeDuration,
    licenseKey,
    null,
    startMeasuring
  );
  const prevSessionState = usePrevious(sessionState);
  const errorMessage = useError(error);
  const warningMessage = useWarning(warning);
  const isVideoReady = useCallback(() => video.current?.readyState === 4, []);
  const isMeasuring = useCallback(() => sessionState === 2, [sessionState]);

  const handleButtonClick = useCallback(() => {
    setIsLoading(true);
    if (sessionState === 1) {
      setStartMeasuring(true);
      setLoadingTimeoutPromise(
        window.setTimeout(() => setIsLoading(true), processingTime * 1000)
      );
    } else if (isMeasuring()) {
      clearTimeout(loadingTimeoutPromise);
      setStartMeasuring(false);
    }
  }, [sessionState, setIsLoading, processingTime]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     handleButtonClick();
  //     console.log("useEffect isMeasuring:" + isMeasuring());
  //   }, 5000);

  //   return () => clearTimeout(timer);
  // }, []);

  useEffect(() => {
    if (isMeasuring()) {
      setIsLoading(false);
      if (errorMessage) {
        setIsMeasurementEnabled(false);
      } else {
        setIsMeasurementEnabled(true);
      }
      setStartMeasuring(false);
    } else if ((sessionState === 1 || sessionState === 4) && errorMessage) {
      setIsMeasurementEnabled(false);
    }
    if (sessionState === 1 && prevSessionState !== sessionState) {
      setStartMeasuring(false);
      setIsLoading(false);
    }
  }, [errorMessage, sessionState]);
  return (
    <>
      {/* {isVideoReady() &&
        (<Skeleton variant="rect" height={"100%"} width={"100%"} animation="wave"/>)}
 */}
      <MonitorWrapper active={!isVideoReady()}>
        {isMeasuring() && info.message && <Img src={infoMask} />}
        {isMeasuring() && !info.message && <Img src={mask} />}
        <ImageInfo
          infoMessage={info.message}
          errorMessage={errorMessage}
          isMeasuring={isMeasuring()}
          onClick={handleButtonClick}
        />
        <Video
          ref={video}
          id="video"
          muted={true}
          playsInline={true}
          autoPlay={true}
        />
        <Stats
          vitalSigns={vitalSigns}
          isMeasuring={isMeasuring()}
          errorMessage={errorMessage}
          infoMessage={info.message}
        />
        {(isMeasuring() || errorMessage) && (
          <ProgressBar
            isMeasuring={isMeasuring()}
            isLoading={isLoading}
            errorMessage={errorMessage}
            processingTimeDuration={processingTimeDuration}
            vitalSigns={vitalSigns}
            infoMessage={info.message}
          />
        )}
      </MonitorWrapper>
      <Backdrop open={!isVideoReady()}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {errorMessage?.includes("1001") && (
        <Alert severity="error">Your decice camera is already open </Alert>
      )}
      {errorMessage?.includes("1005") && (
        <Alert severity="error">Camera permissions not granted</Alert>
      )}
    </>
  );
};

const MonitorWrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  ${({ active }) =>
    active &&
    `
  display:none;
  `}
`;

const Video = styled.video`
  width: 100%;
  height: 99vh;
  object-fit: cover;
  box-sizing: border-box;
  ${mirror}
`;

const Img = styled.img`
  position: absolute;
  width: 80%;
  height: 80%;
  z-index: 1;
  top: 30%;
  left: 50%;
  @media only screen and (min-width: 768px) {
    width: 90%;
    height: 50%;
  }
  object-fit: "cover";
  position: absolute;
  transform: translate(-50%, -50%);
`;

const Info = styled.div`
  position: absolute;
  z-index: 2;
  left: 35%;
  top: 25%;
  width: 20%;
  background-color: yellow;
`;
