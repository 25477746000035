import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import BinahApp from './BinahApp.js'

const App = () => {
  return (
      <BinahApp/>
  );
};

export default App;
