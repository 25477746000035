import { useCallback, useEffect, useRef, useState } from "react";
import monitor, {
  DeviceOrientation,
  HealthMonitorCodes,
  ImageValidity,
  SessionState
} from "@binah/web-sdk";

const InfoType = {
  NONE: "NONE",
  INSTRUCTION: "INSTRUCTION",
  SUCCESS: "SUCCESS"
};

const useMonitor = (
  video,
  cameraId,
  processingTime,
  licenseKey,
  productId,
  startMeasuring
) => {
  const [session, setSession] = useState();
  const [sessionState, setSessionState] = useState();
  const [isMonitorReady, setIsMonitorReady] = useState();
  const [enabledVitalSigns, setEnabledVitalSigns] = useState();
  const [offlineMeasurements, setOfflineMeasurements] = useState();
  const [vitalSigns, setVitalSigns] = useState();
  const [error, setError] = useState({ code: -1 });
  const [warning, setWarning] = useState({ code: -1 });
  const [info, setInfo] = useState({ type: InfoType.NONE });
  const isDismissing = useRef(false);

  const setInfoWithDismiss = useCallback(
    (info, seconds) => {
      if (!isDismissing.current) {
        setInfo(info);
        if (seconds) {
          isDismissing.current = true;
          setTimeout(() => {
            setInfo({ type: InfoType.NONE });
            isDismissing.current = false;
          }, seconds * 1000);
        }
      }
    },
    [InfoType, setInfo, info, isDismissing, isDismissing.current]
  );

  const updateVitalSigns = useCallback(vitalSigns => {
    setVitalSigns(prev => ({
      ...prev,
      ...vitalSigns
    }));
  }, []);
  const onVitalSign = useCallback(vitalSign => {
    updateVitalSigns(vitalSign);
  }, []);

  const onFinalResults = useCallback(vitalSignsResults => {
    window.parent.postMessage({message:vitalSignsResults.results , hide: 'dbhchat', show: 'dbhchat'}, '*');
    updateVitalSigns(vitalSignsResults.results);
    //console.log(vitalSignsResults.results,"final vital")
  }, []);

  const onError = errorData => {
    setError(errorData);
  };

  const onWarning = warningData => {
    if (warningData.code === 3500) {
      setVitalSigns(null);
    }
    if (warningData.code === 3504) {
      setInfo({
        message: `Warning: ${warningData.code}`,
        type: InfoType.INSTRUCTION
      });
    } else {
      setWarning(warningData);
    }
  };

  const onStateChange = useCallback(state => {
    setSessionState(state);
    if (state === 2) {
      setVitalSigns(null);
    }
  }, []);

  const onEnabledVitalSigns = useCallback(vitalSigns => {
    setEnabledVitalSigns(vitalSigns);
  }, []);

  const onOfflineMeasurement = useCallback(offlineMeasurements => {
    setOfflineMeasurements(offlineMeasurements);
  }, []);

  // eslint-disable-next-line no-unused-vars
  const onActivation = useCallback(activationId => {
    // the device has been activated with activationId
  }, []);

  const onImageData = useCallback( imageValidity => {
    let message;
    if (imageValidity != ImageValidity.VALID) {
      switch (imageValidity) {
        case ImageValidity.INVALID_DEVICE_ORIENTATION:
          message = "Unsupported Orientation";
          break;
        case ImageValidity.TILTED_HEAD:
          message = "Head Tilted";
          break;
        case ImageValidity.FACE_TOO_FAR: // Placeholder, currently not supported
          message = "You Are Too Far";
          break;
        case ImageValidity.UNEVEN_LIGHT:
          message = "Uneven Lighting";
          break;
        case ImageValidity.INVALID_ROI:
        default:
          message = "Face Not Detected";
      }
      setInfo({
        type: InfoType.INSTRUCTION,
        message: message
      });
    } else {
      setInfoWithDismiss({ type: InfoType.NONE });
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if(licenseKey){
        await monitor.initialize({
          licenseKey,
          licenseInfo: {
            onEnabledVitalSigns,
            onOfflineMeasurement,
            onActivation
          }
        });
        console.log(`Initialized monitor`);
        setIsMonitorReady(true);
        setError({ code: -1 });
      }
      } catch (e) {
        console.error("Error initializing HealthMonitor", e);
        setIsMonitorReady(false);
        setError({ code: e.errorCode });
      }
    })();
  }, [licenseKey]);

  useEffect(() => {
    (async () => {
      try {
        if ( !isMonitorReady || !processingTime || !video.current) {
          return;
        }

        sessionState === 1 && session.terminate();
        const options = {
          input: video.current,
          cameraDeviceId: cameraId,
          processingTime,
          onVitalSign,
          onFinalResults,
          onError,
          onWarning,
          onStateChange,
          orientation: 0,
          onImageData
          /*******************************************************************************
           * For accurate vital signs calculation the user's parameters should be provided.
           * The following is an example of how the parameters are passed to the SDK:
           *
           * subjectDemographic: {age: 35, weight: 75, gender: Gender.MALE}
           *
           * When measuring a new user then a new session must be created using the
           * new user's parameters
           * The parameters are used locally on the device only for the vital sign
           * calculation, and deleted when the session is terminated.
           *******************************************************************************/
        };
        const faceSession = await monitor.createFaceSession(options);
        console.log(`Session created`);
        setSession(faceSession);
        setError({ code: -1 });
      } catch (e) {
        setError({ code: e.errorCode });
        console.error("Error creating a session", e);
      }
    })();
  }, [processingTime, isMonitorReady]);
  
  useEffect(() => {
    if (startMeasuring) {
      if (sessionState === 1) {
        session.start();
        setError({ code: -1 });
      }
    } else {
      // sessionState === 2 && session.stop();
    }
  }, [startMeasuring]);
  return {
    sessionState,
    vitalSigns: {
      heartRate: {
        label: "Heart Rate",
        value: vitalSigns?.heartRate?.value,
        isEnabled: true,
        confidenceLevel: vitalSigns?.heartRate?.confidenceLevel,
        unit: "BPM"
      },
      breathingRate: {
        label: "Breathing Rate",
        value: vitalSigns?.breathingRate?.value,
        isEnabled: true,
        confidenceLevel: vitalSigns?.breathingRate?.confidenceLevel,
        unit: "RPM"
      },
      stressLevel: {
        label: "Stress Level",
        value: vitalSigns?.stressLevel?.value,
        isEnabled: true,
        confidenceLevel: vitalSigns?.stressLevel?.confidenceLevel,
        unit: "Medium"
      },
      sdnn: {
        label: "HRV-SDNN",
        value: vitalSigns?.sdnn?.value,
        isEnabled: false,
        confidenceLevel: vitalSigns?.sdnn?.confidenceLevel,
        unit: "SDNN"
      },
      spo2: {
        label: "Oxygen Saturation",
        value: vitalSigns?.oxygenSaturation?.value,
        isEnabled: false,
        confidenceLevel: vitalSigns?.oxygenSaturation?.confidenceLevel,
        unit: "SpO2%"
      },
      bloodPressure: {
        label: "Blood Pressure",
        value: vitalSigns?.bloodPressure?.value,
        isEnabled: true,
        confidenceLevel: vitalSigns?.bloodPressure?.confidenceLevel,
        unit: "mmHg"
      },
      stressIndex: {
        label: "Stress Index",
        value: vitalSigns?.stressIndex?.value,
        isEnabled: false,
        confidenceLevel: vitalSigns?.stressIndex?.confidenceLevel,
        unit: ""
      },
      meanRri: {
        label: "Mean Rri",
        value: vitalSigns?.meanRri?.value,
        isEnabled: false,
        confidenceLevel: vitalSigns?.meanRri?.confidenceLevel,
        unit: ""
      },
      rmssd: {
        label: "Rmssd",
        value: vitalSigns?.rmssd?.value,
        isEnabled: false,
        confidenceLevel: vitalSigns?.rmssd?.confidenceLevel,
        unit: ""
      },
      sd1: {
        label: "SD1",
        value: vitalSigns?.sd1?.value,
        isEnabled: false,
        confidenceLevel: vitalSigns?.sd1?.confidenceLevel,
        unit: ""
      },
      sd2: {
        label: "SD2",
        value: vitalSigns?.sd2?.value,
        isEnabled: false,
        confidenceLevel: vitalSigns?.sd2?.confidenceLevel,
        unit: ""
      },
      prq: {
        label: "PRQ",
        value: vitalSigns?.prq?.value,
        isEnabled: false,
        confidenceLevel: vitalSigns?.prq?.confidenceLevel,
        unit: ""
      },
      prq: {
        label: "PRQ",
        value: vitalSigns?.prq?.value,
        isEnabled: false,
        confidenceLevel: vitalSigns?.prq?.confidenceLevel,
        unit: ""
      },
      pnsIndex: {
        label: "Pns Index",
        value: vitalSigns?.pnsIndex?.value,
        isEnabled: false,
        confidenceLevel: vitalSigns?.pnsIndex?.confidenceLevel,
        unit: ""
      },
      pnsZone: {
        label: "Pns Zone",
        value: vitalSigns?.pnsZone?.value,
        isEnabled: false,
        confidenceLevel: vitalSigns?.pnsZone?.confidenceLevel,
        unit: ""
      },
      snsIndex: {
        label: "Sns Index",
        value: vitalSigns?.snsIndex?.value,
        isEnabled: false,
        confidenceLevel: vitalSigns?.snsIndex?.confidenceLevel,
        unit: ""
      },
      snsZone: {
        label: "Sns Index",
        value: vitalSigns?.snsZone?.value,
        isEnabled: false,
        confidenceLevel: vitalSigns?.snsZone?.confidenceLevel,
        unit: ""
      },
      wellnessLevel: {
        label: "Wellness Level",
        value: vitalSigns?.wellnessLevel?.value,
        isEnabled: false,
        confidenceLevel: vitalSigns?.wellnessLevel?.confidenceLevel,
        unit: ""
      },
      wellnessIndex: {
        label: "Wellness Index",
        value: vitalSigns?.wellnessIndex?.value,
        isEnabled: true,
        confidenceLevel: vitalSigns?.wellnessIndex?.confidenceLevel,
        unit: ""
      },
      lfhf: {
        label: "Lfhf",
        value: vitalSigns?.lfhf?.value,
        isEnabled: false,
        confidenceLevel: vitalSigns?.lfhf?.confidenceLevel,
        unit: ""
      },
      lfhf: {
        label: "Lfhf",
        value: vitalSigns?.lfhf?.value,
        isEnabled: false,
        confidenceLevel: vitalSigns?.lfhf?.confidenceLevel,
        unit: ""
      },
      hemoglobin: {
        label: "Hemoglobin",
        value: vitalSigns?.hemoglobin?.value,
        isEnabled: true,
        confidenceLevel: vitalSigns?.hemoglobin?.confidenceLevel,
        unit: "g/dl"
      },
      hemoglobinA1c: {
        label: "HemoglobinA1c",
        value: vitalSigns?.hemoglobinA1c?.value,
        isEnabled: false,
        confidenceLevel: vitalSigns?.hemoglobinA1c?.confidenceLevel,
        unit: ""
      },
    },
    offlineMeasurements,
    error,
    warning,
    info
  };
};

export default useMonitor;
