import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import Progress from '../assets/icons/Progress.svg';
import heartRate from '../assets/icons/heartRate.svg';
import bloodPressure from '../assets/icons/bloodPressure.svg';
import breathingRate from '../assets/icons/breathingRate.svg';
import hemoglobin from '../assets/icons/hemoglobin.svg';
import hrvSdnn from '../assets/icons/hrvSdnn.svg';
import spo2 from '../assets/icons/spo2.svg';
import stressLevel from '../assets/icons/stressLevel.svg';
import wellnessIndex from '../assets/icons/wellnessIndex.svg';

const useStyles = makeStyles((theme) => ({
  normal:{
  //boxSize: "border-box",
  backgroundColor: "rgba(255, 255, 255, 0.85)",
  fontWeight: 500,
  borderRadius: "6px",
  padding:"1%",
  margin:"2px",
  maxWidth:"32%"
  },
  info:{
    //boxSize: "border-box",
    backgroundColor: "rgba(231, 223, 223, 0.85)",
    fontWeight: 500,
    borderRadius: "6px",
    padding:"1%",
    margin:"2px",
    maxWidth:"32%"
  },
  error:{
  //boxSize: "border-box",
  backgroundColor: "rgba(237, 215, 212, 0.85)",
  fontWeight: 500,
  borderRadius: "6px",
  padding:"1%",
  margin:"2px",
  maxWidth:"32%"
  }  
}));


const Title = styled.div`
  text-align: center;
  font-size: 0.8rem;
  font-family: 'Inter', sans-serif;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px, 0px, 2px, 0px;
  height: 52px;
`;

const Value = styled.div`
  font-size: 21px;
  color: rgba(89, 128, 25, 1);
  font-family: 'Inter', sans-serif;
`;

const IconSection = styled.div`
  display: flex;
  align-items: flex-start;
`;

const StatsBox = ({ icon, label, progressIcon, uiBackgroundColor, value, unit }) => {

  function getIcons(iconName) {
    if (iconName === "heartRate") {
      return heartRate;
    }
    else if (iconName === "bloodPressure") {
      return bloodPressure;
    }
    else if (iconName === "breathingRate") {
      return breathingRate;
    }
    else if (iconName === "hemoglobin") {
      return hemoglobin;
    }
    else if (iconName === "hrvSdnn") {
      return hrvSdnn;
    }
    else if (iconName === "spo2") {
      return spo2;
    }
    else if (iconName === "stressLevel") {
      return stressLevel;
    }
    else {
      return wellnessIndex;
    }
  }

  const classes = useStyles();

  return (
    <Grid item xs={4} sm={4} md={4} className={uiBackgroundColor === "info" ? classes.info : uiBackgroundColor === "error" ? classes.error : classes.normal}>
      <Title>{label}</Title>
      <BodyWrapper>
        {value ? (
          <>
            <Value>{value}</Value>
            <Title>{unit}</Title>
          </>
        ) : (
          <img src={progressIcon} />
        )}
      </BodyWrapper>
      <IconSection>
        <img src={getIcons(icon)} alt="icon" />
      </IconSection>
    </Grid>
  );
};

export default StatsBox;
