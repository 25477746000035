import React, { useEffect, useState } from "react";
import useCameras from "../hooks/useCameras";
import { BinahMonitor } from "./BinahMonitor";
import { Container, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    padding: 0
  }
});

const BinahApp = props => {
  const cameras = useCameras();
  const [cameraId, setCameraId] = useState();
  const classes = useStyles();
  useEffect(() => {
    if (!cameras?.length) return;
    setCameraId(cameras[0].deviceId);
  }, [cameras]);

  useEffect(() => {});
  return (
    <Container maxWidth="xs" className={classes.root}>
      <BinahMonitor
        showMonitor={true}
        cameraId={cameraId}
        licenseKey={props.licenseKey}
        processingTime={props.processingTime}
      />
    </Container>
  );
};

export default BinahApp;
